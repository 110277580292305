import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { jackColors } from "../../../../assets/colors";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { DropdownButtonJack } from "../../../../components/ButtonsJack/dropdown";
import { digitDateFormatter, simpleDate } from "../../../../components/tools";
import { ToasterHook } from "../../../../contexts/ToasterContext";
import { apiBusiness, handleDownloadRaw } from "../../../../tools/api";
import { eventsTracker } from "../../../../universalFunctions/events";
import { queryDate } from "../filters/buttons/dropdowns/date/logics";
import { useTransactionFilters } from "../logics/filters";
import { TransactionExportModal } from "./modal";
import DemoGeneralModal from "../../../productDemoComponents/components/DemoGeneralModal";
import { useModalHook } from "../../../../components/Modals";
import {
  DEMO_ADD_WORKFLOW_CASE,
  DEMO_CHOOSE_EXPORT_REPORT,
  DEMO_CLICK_EXPORT_REPORT,
  DEMO_POPUP_REGISTER,
} from "../../../productDemoComponents/constants/events";

const createFileNameFromToXls = ({ string, from, to, format = "xlsx" }) => {
  return `${string}-${digitDateFormatter(from)}-to-${digitDateFormatter(
    to
  )}.${format}`;
};

export const ExportButton = ({ listener = () => {}, isEmployee }) => {
  const array = isEmployee
    ? [
        {
          label: "Filtered report",
          value: "source_account",
          msg: "Selected transactions based on your needs",
        },
      ]
    : [
        {
          label: "General report",
          value: "company_balance",
          msg: "All company transactions and balance ledger",
        },
        {
          label: "Filtered report",
          value: "source_account",
          msg: "Selected transactions based on your needs",
        },
      ];

  const [value, setValue] = useState("");

  const close = () => setValue("");

  const isSource = value == "source_account";

  useEffect(() => {
    listener(!!value);
  }, [!!value]);

  const title = isSource ? "Export filtered report" : "Export general report";
  const msg = isSource
    ? "You can select filter of transaction based on your needs"
    : "Select date range of all transaction you want to export";

  const { query } = useRouter();

  const getDate = (keyword) => simpleDate(queryDate(query, keyword));

  const fromDate = getDate("from_date");
  const toDate = getDate("to_date");

  const [isExceeded, setIsExceeded] = useState(false);
  const [missingCategoryCount, setMissingCategoryCount] = useState(0);

  const [loading, setLoading] = useState(false);

  const urlDecider = (format) => {
    const isXLSX = format === "xlsx";
    const isPDF = format === "pdf";

    if (isXLSX)
      return `/activity_report.xlsx?date_from=${fromDate}&date_to=${toDate}`;
    if (isPDF)
      return `/activity_report.pdf?date_from=${fromDate}&date_to=${toDate}`;
    return null;
  };

  const { payload: payloadRaw } = useTransactionFilters();
  const { page, per_page, ...filters } = payloadRaw?.filters;

  const type_of_report = isSource ? "activity_report" : "balance_history";

  const payload = !isSource
    ? {
        type_of_report: "balance_history",
        filters: {
          range: filters?.range,
        },
      }
    : { filters, type_of_report, keyword: "" };

  const filtersString = String(
    Object.values(filters).map((array) => String(array))
  );

  useEffect(async () => {
    if (!fromDate) return;
    if (!toDate) return;
    try {
      setLoading(true);
      const { data } = await apiBusiness.post(url, payload);
      const { status, missing_categories } = data || {};
      const is202 = status == 202;
      setMissingCategoryCount(missing_categories ?? 0);
      setIsExceeded(is202);
    } catch (error) {
      console.log("error:", error);
    } finally {
      setLoading(false);
    }
  }, [fromDate, toDate, filtersString]);

  const { successToaster, errorToaster, errorSnackBar } = ToasterHook();

  const onSubmit = async (toggle, format) => {
    const url = urlDecider(format);
    const fileType = format === "xlsx" ? "excel" : "pdf";
    const mimeType =
      format === "xlsx"
        ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        : "application/pdf";

    try {
      setLoading(true);
      const formattedPayload = {
        ...payload,
        force_download: true,
        file_type: fileType,
      };

      const res = await apiBusiness.post(url, formattedPayload, {
        responseType: "blob",
      });

      const { data } = res;

      const createFileName = (string) =>
        createFileNameFromToXls({ string, from: fromDate, to: toDate, format });

      const isSentToEmail = data?.type == "application/json";

      const eventObj = {
        from: fromDate,
        to: toDate,
      };

      const homeOrActivity = isSource ? "activity" : "home";

      if (isSentToEmail || isExceeded) {
        successToaster({
          title: "Export in progress",
          msg: "You will receive an email shortly.",
        });
        toggle();
        eventsTracker(`download_report_to_email_${homeOrActivity}`, eventObj);
        return setLoading(false);
      }

      handleDownloadRaw({
        data,
        type: mimeType,
        fileName: isSource
          ? "Jack-Activity-History"
          : createFileName("Jack-Balance-History"),
      });
      successToaster({
        title: "Export in progress",
        msg: "We’re exporting your file. The download process will start shortly.",
      });
      eventsTracker(`download_report_activity_${homeOrActivity}`, eventObj);
      toggle();
    } catch (error) {
      const { response } = error;
      const { status } = response || {};
      const is422 = status == 422;

      if (!is422)
        return errorSnackBar({ msg: "No transactions in the selected range" });

      const { message } = JSON.parse(await response?.data.text());

      errorSnackBar({ msg: message });
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    close();
    setMissingCategoryCount(0);
  };

  // DEMO PURPOSE
  const { isOpen, toggle } = useModalHook();
  const handleRestrict = () => {
    eventsTracker(DEMO_POPUP_REGISTER, { page_title: "transaction" });
    toggle();
  };
  const handleClick = (val) => {
    // dihide for demo purpose
    // (val) => setValue(val)
    const isCompanyBalance = val === "company_balance";
    eventsTracker(DEMO_CHOOSE_EXPORT_REPORT, {
      page_title: "transaction",
      export_report_type: isCompanyBalance ? "general" : "filtered",
    });
    handleRestrict();
  };

  return (
    <>
      <DropdownButtonJack
        leftIcon={<JackIcons name="addchart" fill={jackColors.black34} />}
        array={array}
        onClick={handleClick}
        customFunction={() => {
          eventsTracker(DEMO_CLICK_EXPORT_REPORT, {
            page_title: "transaction",
          });
        }}
      >
        Export
      </DropdownButtonJack>
      <TransactionExportModal
        isOpen={!!value}
        toggle={handleClose}
        title={title}
        msg={msg}
        noFilter={!isSource}
        onSubmit={onSubmit}
        missingCategoryCount={missingCategoryCount}
        loading={loading}
      />
      <DemoGeneralModal
        isOpen={isOpen}
        toggle={toggle}
        pageTitle="transaction"
      />
    </>
  );
};
