import React, { useEffect, useState } from "react";
import { jackColors } from "../../assets/colors";
import TabMenu from "../../components/TabMenu/parent";
import { GothamMedium } from "../../components/Text";
import { getUserButtonBooleans, getUserRole } from "../../contexts/AuthContext";
import { useHeader } from "../../contexts/Layout/parent";
import { ExportButton } from "./transactions/export/parent";
import { useExportLogics } from "./transactions/filters/logics";
import { TransactionsPage } from "./transactions/parent";
import { CategoryWelcomingModal } from "../categoryComponents/general/components";
import ApiDisbursementPageComponent from "../jackApiDisbursementComponents/parent";
import { useRouter } from "next/router";
import { ApiHistoryExportButton } from "../jackApiDisbursementComponents/buttons/export";
import ContentTab from "../virtualAccountComponents/menu/contentTab";
import {
  TRANSACTIONS_FIFTH_TARGET,
  TRANSACTIONS_MODULE,
} from "../productDemoComponents/constants";
import useGuidedTourBuilder from "../productDemoComponents/builders";
import { useGuidedTour } from "../../contexts/GuidedTourContext";
import { eventsTracker } from "../../universalFunctions/events";
import { DEMO_PAGE_VIEW } from "../productDemoComponents/constants/events";

export const TransactionsTab = ({
  isStopQuery,
  isRecent,
  // setIsTourReady = () => {},
}) => {
  const props = { isRecent, isStopQuery };
  const { isManagingTeams, isEmployee, isAdminOrSuperAdmin, isBookKeeper } =
    getUserRole();
  const { canViewApiDisbursement } = getUserButtonBooleans();

  const canShowCompanyTrx = !isEmployee;

  // div or fragment will tell react to treat it as a new component

  const menu = (
    <TabMenu
      deleteQueries
      style={{
        paddingLeft: isRecent ? 0 : 32,
        paddingRight: isRecent ? 0 : 32,
      }}
      menus={[
        canShowCompanyTrx && {
          screen: (
            <>
              <TransactionsPage {...props} />
            </>
          ),
          screenName: "Company", // changed from "Company Transaction" in VA 2.0
        },
        isManagingTeams && {
          screen: (
            <div>
              <TransactionsPage type="team" {...props} />
            </div>
          ),
          screenName: "Team Transaction",
        },
        {
          screen: <TransactionsPage type="mine" {...props} />,
          screenName: "My Transaction",
        },
        canViewApiDisbursement &&
          (isAdminOrSuperAdmin || isBookKeeper) && {
            screen: <ApiDisbursementPageComponent transactionPage />,
            screenName: "API Transaction",
          },
        // only for api feature users
        canViewApiDisbursement &&
          !isEmployee && {
            screen: <ContentTab menu="api-trx" />,
            screenName: "API Collection",
          },
      ]}
    />
  );

  if (isRecent)
    return <div style={{ marginLeft: 32, marginRight: 32 }}>{menu}</div>;
  return menu;
};

export const TransactionsComponent = () => {
  const { query } = useRouter();
  const { isTourReady = false } = query ?? {};
  const isApiTransactionMenu = query?.menu === "api_transaction";
  const { setHeader } = useHeader();

  useEffect(() => {
    setHeader({
      type: "createAndBell",
    });
    eventsTracker(DEMO_PAGE_VIEW, { page_title: "transaction" });
  }, []);

  // handles if sourceExportModal isOpened
  const { listener, isOpen } = useExportLogics();
  // handles if sourceExportModal isOpened

  const { isEmployee } = getUserRole();

  // DEMO PURPOSE
  const tooltipProps = useGuidedTourBuilder({
    module: TRANSACTIONS_MODULE,
    isEmployee,
  });

  const { startTour, stopTour } = useGuidedTour({
    ...tooltipProps,
    props: { continuous: true },
  });

  useEffect(() => {
    if (!isTourReady) return;
    setTimeout(() => startTour(), 500);

    return () => stopTour();
  }, [isTourReady]);

  return (
    <>
      <div
        style={{
          paddingLeft: 32,
          paddingRight: 32,
          marginBottom: 24,
        }}
        className="d-flex"
      >
        <GothamMedium
          className="font24"
          style={{ color: jackColors.black34, marginRight: 16 }}
        >
          Transaction
        </GothamMedium>
        {!isEmployee && isApiTransactionMenu ? (
          <ApiHistoryExportButton />
        ) : (
          <span id={TRANSACTIONS_FIFTH_TARGET}>
            <ExportButton listener={listener} isEmployee={isEmployee} />
          </span>
        )}
      </div>
      <TransactionsTab isStopQuery={isOpen} />
      <CategoryWelcomingModal />
    </>
  );
};
