import React, { useEffect, useReducer, useRef, useState } from "react";
import EmptyList from "../common/emptyList";
import { TransactionsFilters } from "../../transactionsComponents/transactions/filters/parent";
import { useTransactionFiltersArray } from "../../transactionsComponents/transactions/logics/filterArray";
import { TableBodySkeleton } from "../../../components/Shimmer";
import { TableJackSimple } from "../../../components/Table/parent";
import ViewportList from "react-viewport-list";
import { VATableItem } from "../common/tableItem";
import { useRouter } from "next/router";
import { useDeactivateVa, useFetchVA } from "../common/hooks";
import { vaFormatter } from "../common/utils";
import { ConfirmationModalJack } from "../../../components/ButtonsJack/confirmation";
import { useModalHook } from "../../../components/Modals";
import { useMutation } from "../../../tools/api";
import { ToasterHook } from "../../../contexts/ToasterContext";
import deactivateImg from "../../../assets/images/back-previous-page.svg";
import { useDebounce } from "../../../components/tools";
import { useConstants } from "../../../contexts/ConstantsContext/parent";
import { useLayout } from "../../../contexts/Layout/parent";
import { isEmpty } from "lodash";
import FloatingTooltip from "../../productDemoComponents/components/FloatingTooltip";
import { useWindowDimension } from "../../../contexts/DimensionContext";

const ContentTab = ({ menu = "active" }) => {
  const [deactivateId, setDeactivateId] = useState(0);
  const [_, forceUpdate] = useState(0);

  const { push, query } = useRouter();
  const { menu: queryMenu, runQuery, showPopup, ...rest } = query || {};
  const { canRefetch } = useLayout();
  const tableTotalWidth = 1136;
  const tableWidth = (number) => `${(number / tableTotalWidth) * 100}%`;
  const isArchived = menu === "archived";
  const isAPI = menu === "api";
  const isTransactionPage = menu === "api-trx";

  const { data, loading, nextPage, refetch, afterDelete, setData } = useFetchVA(
    {
      type: "index",
      menu: menu,
    }
  );

  const { vaBankOptions: bankOptions } = useConstants();

  const statusOptions = [
    { label: "Active", value: "active" },
    { label: "Need Update", value: "need update" },
    { label: "Expired", value: "expired" },
    { label: "Deactivated", value: "deactivated" },
  ];

  const statusOptionsTrx = [
    { label: "Success", value: "success" },
    { label: "waiting", value: "waiting" },
  ];

  const { successSnackBar } = ToasterHook();

  const { mutation: mutationDeactivateVa } = useDeactivateVa({
    vaId: deactivateId,
    afterSuccess: (res) => {
      const vaData = res?.data?.data;
      successSnackBar({ msg: `${vaData?.number} have been deactivated` });
      refetch();
    },
  });

  const { isOpen: isDeactivateModalOpen, toggle } = useModalHook();

  const handleDeactivateVa = () => {
    if (!deactivateId) return null;
    return mutationDeactivateVa();
  };

  const expiryDateFilter = {
    label: "Expiry Date",
    name: "exp_date",
    type: "date",
    icon: "calendar",
    queryNames: ["from_date", "to_date"],
  };

  const statusFilter = {
    icon: "loader-outline",
    label: "Status",
    name: "va_status",
    search: true,
    type: "status",
    loading: false,
    array: isTransactionPage ? statusOptionsTrx : statusOptions,
  };

  const bankFilter = {
    icon: "bank",
    label: "Bank Name",
    name: "bank_name",
    search: true,
    type: "status",
    loading: false,
    array: bankOptions,
  };

  const filterDecider = () => {
    let defaultActiveFilters = ["exp_date", "va_status", "bank_name"];
    let defaultFilter = [expiryDateFilter, statusFilter, bankFilter];

    if (isArchived) {
      // archived dont need status filter
      defaultActiveFilters = ["exp_date", "bank_name"];
      defaultFilter = [expiryDateFilter, bankFilter];
    }

    if (isAPI) {
      // index VA API wont have exp date filter temporarily
      defaultActiveFilters = ["va_status", "bank_name"];
      defaultFilter = [statusFilter, bankFilter];
    }

    if (isTransactionPage) {
      // Trx API dont need bank filter
      defaultActiveFilters = ["exp_date", "va_status"];
      defaultFilter = [expiryDateFilter, statusFilter];
    }

    return {
      defaultActiveFilters,
      defaultFilter,
    };
  };

  const activeFilters = filterDecider().defaultActiveFilters;
  const filters = filterDecider().defaultFilter.filter((item) => item);

  const tbodyRef = useRef();

  const headerTable = [
    !isAPI && {
      label: "VA Expiry",
      width: tableWidth(104),
      textAlign: "left",
    },
    {
      label: "Reference ID",
      width: tableWidth(104),
      textAlign: "left",
    },
    {
      label: "Display Name",
      width: tableWidth(144),
      textAlign: "left",
    },
    {
      label: "VA Number",
      width: tableWidth(195),
      textAlign: "left",
    },
    {
      label: "Bank Name",
      width: tableWidth(160),
      textAlign: "left",
    },
    {
      label: "Status",
      width: tableWidth(134),
      textAlign: "left",
    },
    {
      label: "Type",
      width: tableWidth(119),
      textAlign: "left",
    },
    !isArchived &&
      !isAPI && {
        label: "",
        width: tableWidth(40),
      },
  ];

  const headerTableTransaction = [
    {
      label: "",
      width: tableWidth(40),
      textAlign: "left",
    },
    {
      label: "Created At",
      width: tableWidth(94),
      textAlign: "left",
    },
    {
      label: "Transaction",
      width: tableWidth(550),
      textAlign: "left",
    },
    {
      label: "Status",
      width: tableWidth(140),
      textAlign: "left",
    },
    {
      label: "Source Account",
      width: tableWidth(148),
      textAlign: "left",
    },
    {
      label: "Amount",
      width: tableWidth(148),
      textAlign: "right",
    },
  ];

  const formatterDecider = () => {
    if (isAPI) return "api";
    if (isTransactionPage) return "api-trx";
    return "index";
  };

  const skeletonDecider = () => {
    if (isAPI) return 6;
    if (isTransactionPage) return 6;
    return 8;
  };

  useEffect(() => {
    if (!deactivateId) return;
    toggle();
  }, [deactivateId]);

  useEffect(() => {
    if (!canRefetch) return;
    nextPage();
  }, [canRefetch]);

  useEffect(() => {
    if (isEmpty(rest)) return;
    push({ query: { menu: queryMenu } });
  }, [queryMenu]);

  const [isOpenTooltip, setIsOpenTooltip] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (loading) return;
    if (!isMounted) return;
    if (!showPopup) return;
    setTimeout(() => {
      setIsOpenTooltip(true);
    }, 500);
  }, [/* queryMenu,  */ isMounted, showPopup, loading]);

  const ref = useRef(null);
  const tableWidthDynamic = tbodyRef?.current?.clientWidth;

  return (
    <>
      <div style={{ padding: "24px 32px 0px 32px" }}>
        <TransactionsFilters
          activeFilters={activeFilters}
          filters={filters}
          woAdd={true}
        />
      </div>

      {!data.length && !loading ? (
        <EmptyList menu={menu} noButton={isArchived} />
      ) : (
        <div
          style={{ padding: 32 }}
          ref={(e) => {
            if (!e) return;
            setIsMounted(true);
          }}
        >
          <FloatingTooltip
            isOpen={isOpenTooltip}
            close={() => {
              delete query?.showPopup;
              push({ query });
              setIsOpenTooltip(false);
            }}
            title="You’ve created virtual account(s)!"
            body="Click here to see the VA details."
            tooltipStyle={{
              translate: `${tableWidthDynamic / 2.7}px -935px`,
              width: 260,
              position: "static",
            }}
            translateTriangle={`130px -10px`}
            position="bottom"
          >
            <TableJackSimple
              tableStyle={{ tableLayout: "auto" }}
              headerTable={
                isTransactionPage ? headerTableTransaction : headerTable
              }
              tbodyRef={tbodyRef}
              bodyComponent={
                <>
                  {!!data.length && (
                    <ViewportList
                      viewportRef={tbodyRef}
                      items={data}
                      itemMinSize={100}
                      margin={8}
                      overscan={10}
                    >
                      {(item, index) => {
                        const vaId = item.id;
                        const isFirstRow = index == 1;
                        // if (isFirstRow) {
                        //   return (
                        //       <FloatingTooltip
                        //         isOpen={true}
                        //         // toggle={closeTooltip}
                        //         close={() => {}}
                        //         title="Invite user"
                        //         body="Click here to check out what needs your attention today."
                        //         tooltipStyle={{
                        //           translate: "233px -75px",
                        //           width: 260,
                        //           position: "fixed",
                        //           top: 0
                        //         }}
                        //         translateTriangle={`-9px 45px`}
                        //         position="right"
                        //       >
                        //         <VATableItem
                        //           index={index}
                        //           item={vaFormatter(item, formatterDecider())}
                        //           rawItem={item}
                        //           key={index}
                        //           isLast={index === data.length - 1}
                        //           onClick={() => {
                        //             if (isAPI || isTransactionPage) return;
                        //             push(`/virtual-account/${vaId}`);
                        //           }}
                        //           setDeactivateId={setDeactivateId}
                        //           refetch={afterDelete}
                        //           isArchived={isArchived}
                        //           isAPI={isAPI}
                        //           isTrxPage={isTransactionPage}
                        //         />
                        //       </FloatingTooltip>
                        //   );
                        // }
                        return (
                          <VATableItem
                            index={index}
                            item={vaFormatter(item, formatterDecider())}
                            rawItem={item}
                            key={index}
                            isLast={index === data.length - 1}
                            onClick={() => {
                              if (isAPI || isTransactionPage) return;
                              push(`/virtual-account/${vaId}`);
                            }}
                            setDeactivateId={setDeactivateId}
                            refetch={afterDelete}
                            isArchived={isArchived}
                            isAPI={isAPI}
                            isTrxPage={isTransactionPage}
                          />
                        );
                      }}
                    </ViewportList>
                  )}
                  {loading && <TableBodySkeleton columns={skeletonDecider()} />}
                </>
              }
            />
          </FloatingTooltip>
        </div>
      )}
      <ConfirmationModalJack
        modal={isDeactivateModalOpen}
        img={deactivateImg}
        toggle={toggle}
        title="Archive this virtual account?"
        text={
          <>
            This VA will be{" "}
            <span style={{ fontFamily: "GothamMedium" }}>deactivated</span> and
            won’t be able to receive payments anymore.
          </>
        }
        onClick={() => handleDeactivateVa()}
      />
    </>
  );
};

export default ContentTab;
